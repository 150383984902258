import firebase from 'firebase/app';
import 'firebase/storage';
const firebaseConfig = {
  apiKey: 'AIzaSyAnnRCkRY-2EzeMPWmOC8dIVSpHfHbLyYU',
  authDomain: 'video-switch.firebaseapp.com',
  databaseURL: 'https://video-switch.firebaseio.com',
  projectId: 'video-switch',
  storageBucket: 'video-switch.appspot.com',
  messagingSenderId: '246785400843',
  appId: '1:246785400843:web:ed3f7c5cbaed7e56c2a70e'
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export { storage, firebase as default };
