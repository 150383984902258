import React from 'react';

import VideoCard from '../../composites/VideoCard/VideoCard';
import SegmentPanelHeader from '../../composites/SegmentPanelHeader/SegmentPanelHeader';
import AssetUploader from '../../composites/AssetUploader/AssetUploader';

import { Container, Draggable } from 'react-smooth-dnd';

import { useVideoStore } from '../../store/videoStore';
import { useEditorViewStore } from '../../store/editorViewStore';

import styles from './SegmentPanel.module.scss';

const SegmentPanel = ({ cards, segmentName }) => {
  const onDrop = useVideoStore((state) => state.onDrop);
  const setExpand = useEditorViewStore((state) => state.setExpand);
  const getCardPayload = useVideoStore((state) => state.getCardPayload);
  const expandedCard = useEditorViewStore((state) => state.expandedCard);
  const setCurrentCard = useEditorViewStore((state) => state.setCurrentCard);
  const previewMode = useEditorViewStore((state) => state.previewMode);
  const deleteCard = useVideoStore((state) => state.deleteCard);
  const sourceColumn = useEditorViewStore((state) => state.sourceColumn);
  const setSource = useEditorViewStore((state) => state.setSource);
  const clearSource = useEditorViewStore((state) => state.clearSource);

  const onDropHandler = (result) => {
    const cardID = result.payload.id;
    onDrop(result, segmentName);
    if (result.addedIndex !== null) {
      deleteCard(cardID, sourceColumn);
      clearSource();
    }
    setCurrentCard(cardID, segmentName);
    if (cardID === expandedCard.id && result.addedIndex != null) {
      setExpand(cardID, segmentName);
    }
  };

  const calcColumnMaxWidth = () => {
    const windowHeight = window.innerHeight;
    const columnMaxW = (windowHeight - 47) * 1.78 * 0.95 - 60; // (windowheight - title height)* 16:9 ratio * 95% - paddings
    return columnMaxW;
  };

  return (
    <div
      className={`${styles.SegmentPanel} ${
        segmentName === 'outro' && cards.length === 0 ? styles.disabled : ''
      } ${
        expandedCard.position === segmentName
          ? styles.expand
          : expandedCard.position != null
          ? styles.shrink
          : ''
      } ${
        previewMode.segment === segmentName
          ? styles.previewCurrent
          : previewMode.on
          ? styles.previewHide
          : ''
      }

      ${expandedCard.position ? styles[expandedCard.position] : ''}
      `}
      style={{ maxWidth: calcColumnMaxWidth() + 'px' }}
    >
      <SegmentPanelHeader
        title={segmentName}
        cards={cards}
        shrink={
          expandedCard.position !== null &&
          expandedCard.position !== segmentName
        }
      />
      <Container
        groupName={'col'}
        onDragStart={(e) => {
          // if (e.isSource) deleteCard(e.payload.id, segmentName);
          // console.error(e.isSource);
        }}
        onDragEnd={(e) => {
          if (e.isSource) setSource(segmentName);
        }}
        onDrop={onDropHandler}
        getChildPayload={(index) => getCardPayload(segmentName, index)}
        dragClass={styles.dragGhost}
        dropClass={styles.dropGhost}
        shouldAcceptDrop={(sourceContainerOptions, payload) => {
          const length = cards.length;
          if (length >= 1) {
            return false;
          } else {
            return true;
          }
        }}
        // onDragLeave={() => {
        //   console.log('drag leave:', segmentName);
        // }}
        // onDragEnter={() => {
        //   console.log('drag enter:', segmentName);
        // }}
        // onDropReady={(p) => console.log('Drop ready: ', p)}
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: `${styles.dropPreview}`
        }}
        nonDragAreaSelector='.trimmer, .expand, .button'
      >
        {cards.map((card, i) => {
          return (
            <Draggable key={card.id}>
              <div className={styles.videoCardWrap}>
                <VideoCard
                  assetID={card.assetID}
                  clips={card.clips}
                  cardID={card.id}
                  position={segmentName}
                  expand={card.id === expandedCard.id ? true : false}
                  shrink={
                    expandedCard.position !== null &&
                    expandedCard.position !== segmentName
                  }
                  index={i}
                  key={card.id}
                />
              </div>
            </Draggable>
          );
        })}
        {cards.length === 0 ? <AssetUploader origin={segmentName} /> : null}
      </Container>
    </div>
  );
};

export default SegmentPanel;
