import create from 'zustand';
// import { mountStoreDevtool } from 'simple-zustand-devtools';

import { generateStateURL } from '../core/utils';

// This store holds most of the other internal UI state
// which controls the behavious of the UI

const useEditorViewStore = create((set, get) => ({
  expandedCard: {
    id: null,
    position: null
  },
  currentCard: {
    id: null,
    position: null
  },
  previewMode: {
    on: false,
    segment: null
  },
  previewStatus: {
    playing: false,
    playingVideoRef: null,
    monitorInterval: null,
    videoID: null
  },
  previewCurrentTime: { currentTime: 0, length: 0 },
  videoRefs: [],
  showUploader: false,
  exportStatus: 'none',
  showSave: false,
  draftURL: '',
  vastURL: '',
  sourceColumn: '',
  error: {
    origin: '',
    message: ''
  },
  showErrorPanel: false,
  // after the expand button in one of the cards is clicked
  setExpand: (cardID, position) =>
    set({ expandedCard: { id: cardID, position: position } }),
  // cancels the card expansion
  cancelExpand: () =>
    set({
      expandedCard: {
        id: null,
        position: null
      }
    }),
  // highlight the card that's currently in focus. mainly used to display the video progress bar when user trims the video
  setCurrentCard: (cardID, position) => {
    set({
      currentCard: {
        id: cardID,
        position: position
      }
    });
  },
  // controls the preview component to show or hide
  setPreviewMode: (position) => {
    set({
      previewMode: {
        on: true,
        segment: position
      }
    });
  },
  // udpates preview status
  updatePreviewStatus: (status, ref, interval, cardID) => {
    set({
      previewStatus: {
        playing: status,
        playingVideoRef: ref,
        cardID: cardID,
        monitorInterval: interval
      }
    });
  },
  // used to control the video progress needle.
  setPreviewCurrentTime: (currentTime, length) => {
    set({
      previewCurrentTime: {
        currentTime: currentTime,
        length: length
      }
    });
  },
  // turns off the previewMode and resets everything
  closePreviewMode: () => {
    set({
      previewMode: {
        on: false,
        segment: null
      },
      previewCurrentTime: { currentTime: 0, length: 0 },
      previewStatus: {
        playing: false,
        playingVideoRef: null,
        monitorInterval: null,
        videoID: null
      }
    });
  },
  // called when each <video/>s on the UI has meta loaded, and stores the Ref for each of them, so other components can access them.
  addVideoRef: (cardID, ref) => {
    const videoRefs = get().videoRefs.slice();
    const filterdRefs = videoRefs.filter((ref) => {
      return ref.videoID !== cardID;
    });
    filterdRefs.push({
      cardID: cardID,
      ref: ref
    });
    set({
      videoRefs: filterdRefs
    });
  },
  // removes the Ref when a card is being deleted
  removeVideoRef: (id) => {
    const videoRefs = get().videoRefs.slice();
    const removingRefIndex = videoRefs.findIndex((ref) => {
      return ref.cardID === id;
    });
    if (removingRefIndex !== -1) {
      videoRefs.splice(removingRefIndex, 1);
      set({
        videoRefs: videoRefs
      });
    }
  },
  toggleUploader: (value) => {
    const uploaderStatus = get().showUploader;
    set({ showUploader: value !== undefined ? value : !uploaderStatus });
  },
  toggleSaveWindow: (value) => {
    const showSave = get().showSave;
    set({ showSave: value !== undefined ? value : !showSave });
  },
  setExportStatus: (status) => {
    set({ exportStatus: status });
  },
  setDraftURL: async (assets, segments) => {
    const draftURL = await generateStateURL({
      assets: assets,
      segments: segments
    });
    set({ draftURL: draftURL });
  },
  setVastURL: (url) => {
    set({ vastURL: url });
  },
  setSource: (sourceColumn) => {
    set({ sourceColumn: sourceColumn });
  },
  clearSource: () => {
    set({ sourceColumn: '' });
  },
  setError: (msg, origin) => {
    set({ error: { origin: origin, message: msg } });
    set({ showErrorPanel: true });
    setTimeout(() => {
      set({ showErrorPanel: false });
    }, 8000);
  },
  clearError: () => {
    set({ error: { origin: '', message: '' } });
  },
  hideError: () => {
    set({ showErrorPanel: false });
  }
}));

// if (process.env.NODE_ENV === 'development') {
//   // mountStoreDevtool('useEditorViewStore', useEditorViewStore);
// }

export { useEditorViewStore };
