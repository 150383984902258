import React from 'react';

import styles from './NavigationPanel.module.scss';
import vsblogo from '@assets/images/vsb-logo-light.png';

import Button from '../../components/Button/Button';

import { useVideoStore } from '../../store/videoStore';
import { useEditorViewStore } from '../../store/editorViewStore';
import { useAssetUploadStore } from '../../store/assetUploadStore';

import {
  faShareSquare,
  faCloudUploadAlt,
  faPuzzlePiece
} from '@fortawesome/free-solid-svg-icons';

const NavigationPanel = () => {
  const segments = useVideoStore((state) => state.segments);
  const setVastURL = useEditorViewStore((state) => state.setVastURL);

  const toggleSaveWindow = useEditorViewStore(
    (state) => state.toggleSaveWindow
  );

  const downloadHanlder = () => {
    setVastURL('');
    toggleSaveWindow(true);
  };

  const checkSegmentsLength = () => {
    const SLength = segments.small.reduce((accumulator, video) => {
      const clipsLength = video.clips.reduce((accu, clip) => {
        return accu + (clip.end - clip.start);
      }, 0);
      accumulator = accumulator + clipsLength;
      return accumulator;
    }, 0);
    const MLength = segments.medium.reduce((accumulator, video) => {
      const clipsLength = video.clips.reduce((accu, clip) => {
        return accu + (clip.end - clip.start);
      }, 0);
      accumulator = accumulator + clipsLength;
      return accumulator;
    }, 0);
    const LLength = segments.large.reduce((accumulator, video) => {
      const clipsLength = video.clips.reduce((accu, clip) => {
        return accu + (clip.end - clip.start);
      }, 0);
      accumulator = accumulator + clipsLength;
      return accumulator;
    }, 0);
    const ALength =
      segments.audible && segments.audible.length
        ? segments.audible.reduce((accumulator, video) => {
            const clipsLength = video.clips.reduce((accu, clip) => {
              return accu + (clip.end - clip.start);
            }, 0);
            accumulator = accumulator + clipsLength;
            return accumulator;
          }, 0)
        : 0;

    return SLength !== 0 && MLength !== 0 && LLength !== 0 && ALength !== 0
      ? true
      : false;
  };

  const readyToDownload = () => {
    let result = false;
    const hasClipsInAllSegments = checkSegmentsLength();
    if (
      segments.small.length &&
      segments.medium.length &&
      segments.large.length &&
      segments.audible.length &&
      hasClipsInAllSegments
    ) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const toggleUploader = useEditorViewStore((state) => state.toggleUploader);

  const uploadQueue = useAssetUploadStore((state) => state.uploadQueue);

  const checkSegmentFull = useVideoStore((state) => state.checkSegmentFull);

  return (
    <div className={styles.Navigation} data-testid='navigation'>
      <div className={styles.logoWrapper}>
        <img className={styles.vsbLogo} src={vsblogo} alt='VSB-logo' />
      </div>
      <div className={styles.ButtonWrapper}>
        <Button
          name='New Asset'
          icon={faCloudUploadAlt}
          style={
            checkSegmentFull()
              ? {}
              : {
                  background:
                    'linear-gradient(135deg, #fe0060 0%,75%, #ff8a3f 100%)'
                }
          }
          status={checkSegmentFull() ? false : true}
          onClickHandler={() => toggleUploader(true)}
          notification={uploadQueue.length ? uploadQueue.length : null}
          tooltip={checkSegmentFull() ? 'All segments are full.' : null}
        ></Button>
        <Button
          name='New Addon'
          icon={faPuzzlePiece}
          status={false}
          tooltip='Feature in development, coming soon.'
        ></Button>
        <Button
          name='Publish'
          status={readyToDownload()}
          icon={faShareSquare}
          tooltip='All segments needs some length in there.'
          style={
            readyToDownload()
              ? {
                  background:
                    'linear-gradient(135deg, #fe0060 0%,75%, #ff8a3f 100%)'
                }
              : null
          }
          onClickHandler={downloadHanlder}
        />
      </div>
    </div>
  );
};

export default NavigationPanel;
