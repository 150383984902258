import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

import { generateHash } from '../core/utils';

// This store holds the base VS build state
// which is going to be used by Switcheroo to compose the final videos
const lodashClonedeep = require('lodash.clonedeep');

const useVideoStore = create((set, get) => ({
  // this is the default state of the application
  assets: [
    // {
    //   id: '16403002-e067-46ed-864a-ce672f19824c',
    //   url:
    //     'https://firebasestorage.googleapis.com/v0/b/video-switch.appspot.com/o/builder-storage%2Fvideo-files%2Fkno.mp4?alt=media&token=5cb596bd-8bae-4df3-bda9-6d995f27d2cb',
    //   duration: 0,
    //   name: 'simpson loop.mp4',
    //   uploadTimeStamp: 1600400521820
    // },
    // {
    //   id: 'test',
    //   url:
    //     "https://firebasestorage.googleapis.com/v0/b/video-switch.appspot.com/o/builder-storage%2Fvideo-files%2FWhat%20It's%20REALLY%20Like%20To%20Fight%20TYSON%20FURY_1.mp4?alt=media&token=72c36a27-9962-4bed-99be-a37e28be33e1",
    //   duration: 0,
    //   name: 'test.mp4',
    //   uploadTimeStamp: 1600400521820
    // }
  ],
  segments: {
    small: [
      // {
      //   id: 0,
      //   assetID: '16403002-e067-46ed-864a-ce672f19824c',
      //   clips: []
      // }
    ],
    medium: [
      // {
      //   id: 1,
      //   assetID: 'test',
      //   clips: []
      // }
    ],
    large: [],
    audible: []
  },
  // onDrop handles state update when a video card gets dropped
  onDrop: (result, position) => {
    const { removedIndex, addedIndex, payload } = result;
    if (removedIndex !== null || addedIndex !== null) {
      const newVideo = payload;
      const segments = get().segments;
      const segmentsCopy = lodashClonedeep(segments);
      if (removedIndex !== null) {
        segmentsCopy[position].splice(removedIndex, 1);
      }
      if (addedIndex !== null) {
        if (position === 'audible') {
          const assets = get().assets;
          const asset = assets.find((asset) => asset.id === newVideo.assetID);
          newVideo.clips = [{ start: 0, end: asset.duration }];
        }
        segmentsCopy[position].splice(addedIndex, 0, newVideo);
      }
      set({ segments: segmentsCopy });
    }
  },
  // getCardPlayload gets the payload object which is the videoCard and passes it into onDrop function.
  getCardPayload: (position, index) => {
    const segments = get().segments;
    const target = segments[position][index];
    return target;
  },
  addNewAsset: (url, fileName) => {
    const assets = get().assets;
    const assetsCopy = [...assets];
    const newAsset = {
      id: generateHash(),
      url: url,
      name: fileName,
      duration: 0,
      uploadTimeStamp: Date.now()
    };
    assetsCopy.push(newAsset);
    set({ assets: assetsCopy });
    return newAsset.id;
  },
  // storing the video asset's duration into the state for easier UI measurement and calculation
  setVideoDuration: (duration, id) => {
    const assets = get().assets;
    const assetsCopy = [...assets];
    const targetAssetIndex = assetsCopy.findIndex((video) => {
      return video.id === id;
    });
    assetsCopy[targetAssetIndex].duration = duration;
    set({ assets: assetsCopy });
    //update the audible segment's card's clips to the new video duration
    const segmentsClone = lodashClonedeep(get().segments);
    const targetCards = segmentsClone.audible.filter((card) => {
      return card.assetID === id;
    });
    if (targetCards.length) {
      targetCards.forEach((card) => {
        const targetIndex = segmentsClone.audible.findIndex((card) => {
          return card.assetID === id;
        });
        segmentsClone.audible[targetIndex].clips = [
          { start: 0, end: duration }
        ];
      });
      set({ segments: segmentsClone });
    }
  },
  addCard: (position, assetID, clips) => {
    const segments = get().segments;
    const segmentsCopy = lodashClonedeep(segments);
    if (position === 'audible') {
      const assets = get().assets;
      const asset = assets.find((asset) => asset.id === assetID);
      const newCard = {
        id: generateHash(),
        assetID: assetID,
        clips: [{ start: 0, end: asset.duration }]
      };
      segmentsCopy[position].splice(0, 0, newCard);
    } else {
      const newCard = {
        id: generateHash(),
        assetID: assetID,
        clips: clips !== undefined && clips.length ? clips : []
      };
      segmentsCopy[position].splice(0, 0, newCard);
    }
    set({ segments: segmentsCopy });
  },
  // removes a video card from the state
  deleteCard: (cardID, position) => {
    const segments = get().segments;
    // const targetCard = segments[position].find((card) => {
    //   return card.id === cardID;
    // });
    // const targetAssetID = targetCard.assetID;

    const segmentsCopy = lodashClonedeep(segments);
    const targetCardIndex = segmentsCopy[position].findIndex((card) => {
      return card.id === cardID;
    });
    segmentsCopy[position].splice(targetCardIndex, 1);
    set({ segments: segmentsCopy });
  },
  // duplicates a video card into the state
  copyCard: (cardID, targetPosition, originPosition) => {
    const segments = get().segments;
    const segmentsCopy = lodashClonedeep(segments);
    const targetCardIndex = segmentsCopy[originPosition].findIndex((card) => {
      return card.id === cardID;
    });
    const cardCopy = lodashClonedeep(
      segmentsCopy[originPosition][targetCardIndex]
    );

    if (targetPosition === 'audible') {
      const assets = get().assets;
      const asset = assets.find((asset) => asset.id === cardCopy.assetID);
      cardCopy.clips = [{ start: 0, end: asset.duration }];
    }
    cardCopy.id = generateHash();
    segmentsCopy[targetPosition].splice(targetCardIndex + 1, 0, cardCopy);
    set({ segments: segmentsCopy });
    return cardCopy;
  },
  // add a new clip into a target videoCard
  addNewClip: (cardID, position, start, end) => {
    const segments = get().segments;
    const segmentsCopy = lodashClonedeep(segments);
    const videoCard = segmentsCopy[position].find((card) => {
      return card.id === cardID;
    });
    videoCard.clips.push({
      start: start,
      end: end
    });
    set({ segments: segmentsCopy });
  },
  // update a clip into with a new start or end, usually called when the trimmer handles are getting dragged
  updateClip: (cardID, clipIndex, position, start, width) => {
    const segments = get().segments;
    const assets = get().assets;
    const segmentsCopy = lodashClonedeep(segments);
    const videoCard = segmentsCopy[position].find((card) => {
      return card.id === cardID;
    });
    const videoDuration = assets.find((video) => video.id === videoCard.assetID)
      .duration;
    videoCard.clips[clipIndex].start = (start / 100) * videoDuration;
    videoCard.clips[clipIndex].end = ((width + start) / 100) * videoDuration;
    set({ segments: segmentsCopy });
  },
  // removes a clip
  deleteClip: (id, position, clipIndex) => {
    const segments = get().segments;
    const segmentsCopy = lodashClonedeep(segments);
    const targetCardIndex = segmentsCopy[position].findIndex((card) => {
      return card.id === id;
    });
    const targetCard = segmentsCopy[position][targetCardIndex];
    targetCard.clips.splice(clipIndex, 1);
    set({ segments: segmentsCopy });
  },
  // updateTheWholeState
  updateState: (assets, segments) => {
    set({ assets: assets, segments: segments });
  },
  checkSegmentFull: () => {
    const segments = get().segments;
    const segmentsValue = Object.values(segments);
    const emptySegments = segmentsValue.filter((segment) => {
      return segment.length === 0;
    });
    return emptySegments.length ? false : true;
  },
  removeUnusedAssets: () => {
    const segmentClone = lodashClonedeep(get().segments);
    // Check if the asset attached to the card deleted is still present in other cards
    const segmentsValue = Object.values(segmentClone).flat();
    const assetsClone = lodashClonedeep(get().assets);
    const validAssets = assetsClone.filter((asset) => {
      const cardWithTheSameAsset = segmentsValue.filter((card) => {
        return card.assetID === asset.id;
      });
      return cardWithTheSameAsset.length !== 0;
    });
    set({ assets: validAssets });
  },
  nextEmptySegment: () => {
    let emptyColumns = [];
    const segments = get().segments;
    for (var key in segments) {
      if (segments.hasOwnProperty(key)) {
        if (!segments[key].length) {
          emptyColumns.push(key);
        }
      }
    }
    return emptyColumns.length ? emptyColumns[0] : null;
  }
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('VideoStore', useVideoStore);
}

export { useVideoStore };
