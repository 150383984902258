import React from 'react';

import styles from './Button.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = (props) => {
  const renderTooltip = () => {
    if (props.status === false) {
      const tooltip = (
        <div className={styles.tooltiptext} data-testid='tooltip'>
          {props.tooltip}
        </div>
      );
      return tooltip;
    } else {
      return null;
    }
  };

  const renderNotification = () => {
    if (props.notification) {
      const notification = (
        <div className={styles.notification}>{props.notification}</div>
      );
      return notification;
    } else {
      return null;
    }
  };
  return (
    <div
      className={`
        ${styles.Button} ${props.status ? '' : styles.disabled}
        ${props.tooltip && !props.status ? styles.tooltip : ''}
        ${props.pulse ? styles.pulse : ''}
      `}
      style={props.style ? props.style : null}
      onClick={props.status ? props.onClickHandler : null}
      data-testid='button'
    >
      {props.icon ? (
        <FontAwesomeIcon
          icon={props.icon}
          spin={props.spin}
          style={props.name ? { marginRight: '10px' } : null}
        />
      ) : null}
      {props.name ? props.name : null}
      {renderTooltip()}
      {renderNotification()}
    </div>
  );
};

export default Button;
