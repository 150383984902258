import React from 'react';

import styles from './Previewer.module.scss';

import PreviewVideoPlayer from '../../components/PreviewVideoPlayer/PreviewVideoPlayer';

import { useEditorViewStore } from '../../store/editorViewStore';

const Previewer = () => {
  const previewMode = useEditorViewStore((state) => state.previewMode);
  const previewSegment = previewMode.segment;

  return (
    <div
      className={`${styles.previewer} ${previewMode.on ? styles.active : null}`}
    >
      <PreviewVideoPlayer segment={previewSegment} />
    </div>
  );
};
export default Previewer;
