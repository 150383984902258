import React, { useState } from 'react';

import styles from './PreviewVideoController.module.scss';
import { useEditorViewStore } from '../../store/editorViewStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUndoAlt,
  faStop,
  faVolumeMute,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons';

const PreviewVideoController = ({ playing, canvasRef }) => {
  const updatePreviewStatus = useEditorViewStore(
    (state) => state.updatePreviewStatus
  );

  const previewStatus = useEditorViewStore((state) => state.previewStatus);
  const videoRefs = [...useEditorViewStore((state) => state.videoRefs)];

  const [muted, setMuted] = useState(true);

  const playClickHandler = () => {
    if (previewStatus.playing) {
      videoRefs.forEach((videoRef) => {
        if (videoRef.ref.current && videoRef.ref.current != null) {
          videoRef.ref.current.pause();
        }
      });
      const monitorInterval = previewStatus.monitorInterval;
      clearInterval(monitorInterval);
      updatePreviewStatus(false, null, null, null);
    } else {
      updatePreviewStatus(true, null, null, null);
      clearCanvas();
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const audioHandler = () => {
    videoRefs.forEach((videoRef) => {
      if (videoRef.ref.current && videoRef.ref.current != null) {
        videoRef.ref.current.muted = !muted;
        setMuted(!muted);
      }
    });
  };
  return (
    <div className={styles.controller}>
      <div
        className={styles.play}
        onClick={playClickHandler}
        data-testid='play'
      >
        <FontAwesomeIcon icon={playing ? faStop : faUndoAlt} />
      </div>
      <div className={styles.audio} onClick={audioHandler}>
        <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} />
      </div>
    </div>
  );
};

export default PreviewVideoController;
