import React from 'react';
import styles from './ErrorPanel.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

import { useEditorViewStore } from '../../store/editorViewStore';

const ErroPanel = () => {
  const error = useEditorViewStore((state) => state.error);
  const showErrorPanel = useEditorViewStore((state) => state.showErrorPanel);
  const hideError = useEditorViewStore((state) => state.hideError);
  return (
    <div
      className={styles.errorPanel}
      style={{
        transform: showErrorPanel ? 'translateY(0%)' : 'translateY(100%)'
      }}
    >
      <div className={styles.errorWrapper}>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{ marginRight: '5px' }}
        />
        {error.origin}: {error.message}
        <div className={styles.close} onClick={() => hideError()}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

export default ErroPanel;
