import React, { useEffect, useRef, useState } from 'react';

import Button from '../../components/Button/Button';

import { useVideoStore } from '../../store/videoStore';
import { useEditorViewStore } from '../../store/editorViewStore';

import { generateVastTag, generateHash } from '../../core/utils';
import { storage } from '../../core/firebase';

import styles from './SaveBuild.module.scss';

import draft from '@assets/images/draft.svg';
import publish from '@assets/images/publish.svg';

import {
  faCopy,
  faCog,
  faShareSquare,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

const GOOGLE_CLOUD_STORAGE_VS_BASE_URL = 'https://storage.googleapis.com/video-switch.appspot.com/builder-storage/tag/';

const SaveBuild = ({ type }) => {
  const assetsData = useVideoStore((state) => state.assets);
  const segmentsData = useVideoStore((state) => state.segments);
  const draftURL = useEditorViewStore((state) => state.draftURL);
  const setDraftURL = useEditorViewStore((state) => state.setDraftURL);
  const vastURL = useEditorViewStore((state) => state.vastURL);
  const setVastURL = useEditorViewStore((state) => state.setVastURL);
  const setError = useEditorViewStore((state) => state.setError);
  const removeUnusedAssets = useVideoStore((state) => state.removeUnusedAssets);

  const textAreaRef = useRef(null);
  const vastURLRef = useRef(null);

  const exportStatus = useEditorViewStore((state) => state.exportStatus);
  const setExportStatus = useEditorViewStore((state) => state.setExportStatus);

  useEffect(() => {
    // remove unused assets
    removeUnusedAssets();
    setDraftURL(assetsData, segmentsData);
    // eslint-disable-next-line
  }, [segmentsData, setDraftURL, removeUnusedAssets]);

  const checkResponse = (res) => {
    if (res.status !== 200) {
      console.error(res);
      throw new Error(`Invalid switcheroo URL ${res.url}`);
    }
  };

  const STUDIO_BASE_URL = 'https://studio.playgroundxyz.com';

  const [studioURL, setStudioURL] = useState('');

  const [copyText, setCopyText] = useState('Copy');
  const [copyVASTText, setCopyVASTText] = useState('Copy VAST Tag URL');

  const exportClickHandler = async () => {
    const adName = `Playground XYZ Video Switch - ${generateHash()}`;

    // TODO: Trigger a spinner or something as this can take a while to complete
    setExportStatus('inProgress');

    // Generate the VAST tag content
    const [vastTagContent, segmentJson] = await generateVastTag(
      adName,
      segmentsData,
      assetsData,
      setError
    );

    // Request each segment Media URL from switcheroo in parallel so they're cached and ready
    // to be previewed / adserved.

    try {
      await Promise.all(
        segmentJson.map((s) => fetch(s.fileURL).then(checkResponse))
      );
    } catch (e) {
      setExportStatus('error');
      // TODO: Show an error message and remove the spinner
      return;
    }

    //
    // Upload the VAST tag to storage so we can use it for generating a build in the studio
    //
    await storage
      .ref(`/builder-storage/tag/${adName}.xml`)
      .putString(vastTagContent);

    const vastTagDownloadUrl = `${GOOGLE_CLOUD_STORAGE_VS_BASE_URL}${adName}.xml`;

    let stateQueryParam = '';
    if (draftURL) {
      stateQueryParam = new URLSearchParams(new URL(draftURL).search).get('state')
    }

    const vastTagDownloadUrlWithStateQueryParam = `${vastTagDownloadUrl}?state=${stateQueryParam}`;
    setVastURL(vastTagDownloadUrlWithStateQueryParam);

    // TODO: Remove the spinner
    setExportStatus('idle');

    // Use the link to open URL in a new tab. This will autocreate a build in the studio tool that
    // can be previewed or imported into The Playground for adserving.
    const vastTagUrl = encodeURIComponent(vastTagDownloadUrlWithStateQueryParam);
    const linkUrl = {
      vts: `${STUDIO_BASE_URL}/templates/vs-vts?vastUrl=${vastTagUrl}`,
      vtsRich: `${STUDIO_BASE_URL}/templates/vs-vts-rich?vastUrl=${vastTagUrl}`,
      vtp: `${STUDIO_BASE_URL}/templates/vs-vtp?vastUrl=${vastTagUrl}`,
      vtpRich: `${STUDIO_BASE_URL}/templates/vs-vtp-rich?vastUrl=${vastTagUrl}`
    };
    setStudioURL(linkUrl);
  };

  const copyURLHandler = () => {
    textAreaRef.current.select();
    textAreaRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopyText('Copied!');
    setTimeout(() => {
      setCopyText('Copy');
    }, 2000);
  };

  const copyVASTURLHandler = () => {
    vastURLRef.current.select();
    vastURLRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopyVASTText('Copied!');
    setTimeout(() => {
      setCopyVASTText('Copy VAST Tag URL');
    }, 2000);
  };

  const renderURLBox = (url) => {
    return (
      <form>
        <input
          className={styles.urlBox}
          ref={textAreaRef}
          value={url}
          readOnly
        />
        <div className={styles.button}>
          <Button
            name={copyText}
            icon={faCopy}
            status={true}
            onClickHandler={copyURLHandler}
          />
        </div>
      </form>
    );
  };

  const renderPublishButtons = () => {
    return (
      <Button
        name={
          exportStatus === 'inProgress'
            ? 'Generating'
            : exportStatus === 'error'
            ? 'Something went wrong'
            : 'Generate'
        }
        icon={exportStatus === 'error' ? faExclamationCircle : faCog}
        status={true}
        spin={exportStatus === 'inProgress' ? true : false}
        pulse={exportStatus === 'inProgress'}
        style={
          exportStatus === 'inProgress'
            ? { background: '#ff8a3f' }
            : exportStatus === 'error'
            ? { background: '#b62456' }
            : null
        }
        onClickHandler={exportClickHandler}
        data-test
      />
    );
  };

  const renderResponseBox = () => {
    return (
      <div className={styles.response}>
        <span role="img" aria-label="point-left">
          👈
        </span>{' '}
        (Please contact PXYZ with the link provided from the left.)
      </div>
    );
  };

  const renderVASTButtons = (vastURL, studioURL) => {
    return (
      <div className={styles.vastButtons}>
        <form>
          <input
            className={styles.urlBox}
            ref={vastURLRef}
            value={vastURL}
            readOnly
          />
          <div className={styles.button}>
            <Button
              name={copyVASTText}
              icon={faCopy}
              status={true}
              onClickHandler={copyVASTURLHandler}
            />
          </div>
        </form>
        <div className={styles.creativeLinks}>
          <a href={studioURL.vts} target="_blank" rel="noopener noreferrer">
            <Button
              name={'Open Creative (VTS)'}
              icon={faShareSquare}
              status={true}
              style={{
                background:
                  'linear-gradient(135deg, #fe0060 0%, 75%, #ff8a3f 100%)'
              }}
            />
          </a>
          <a href={studioURL.vtsRich} target="_blank" rel="noopener noreferrer">
            <Button
              name={'Open Creative (VTS Rich)'}
              icon={faShareSquare}
              status={true}
              style={{
                background:
                  'linear-gradient(135deg, #fe0060 0%, 75%, #ff8a3f 100%)'
              }}
            />
          </a>
          <a href={studioURL.vtp} target="_blank" rel="noopener noreferrer">
            <Button
              name={'Open Creative (VTP)'}
              icon={faShareSquare}
              status={true}
              style={{
                background:
                  'linear-gradient(135deg, #fe0060 0%, 75%, #ff8a3f 100%)'
              }}
            />
          </a>
          <a href={studioURL.vtpRich} target="_blank" rel="noopener noreferrer">
            <Button
              name={'Open Creative (VTP RIch)'}
              icon={faShareSquare}
              status={true}
              style={{
                background:
                  'linear-gradient(135deg, #fe0060 0%, 75%, #ff8a3f 100%)'
              }}
            />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.saveBuild} data-testid="saveBuild">
      <div className={styles.header}>
        <img src={type === 'draft' ? draft : publish} alt={type} />
      </div>
      <div className={styles.body}>
        <h1>{type === 'draft' ? 'Share draft' : 'Publish'}</h1>
        <p>
          {type === 'draft'
            ? 'Get a sharable link to share a snapshot of your build.'
            : 'Generate a VAST Tag to finalize your build.'}
        </p>
        {/* <p>
          {type === 'draft'
            ? '(You will still be able to edit the segments as how it is currently.)'
            : '(This tag can be used in Video Switch creative.)'}
        </p> */}
        {type === 'draft'
          ? renderURLBox(draftURL)
          : vastURL === ''
          ? renderPublishButtons()
          : renderVASTButtons(vastURL, studioURL)}
        {exportStatus === 'error' && type !== 'draft'
          ? renderResponseBox()
          : null}
      </div>
    </div>
  );
};

export default SaveBuild;
