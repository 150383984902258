import React, { createRef } from 'react';

import styles from './Trimmer.module.scss';

import NewClip from '../../components/NewClip/NewClip';
import { useVideoStore } from '../../store/videoStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripLinesVertical,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

const Trimmer = ({
  clips,
  mouseDownHandler,
  duration,
  position,
  cardID,
  disabled
}) => {
  const renderFiller = () => {
    let validSlots = [];
    const minBoundary = 0,
      minClipLength = 0.1;
    const sortedClips = clips.sort((a, b) => {
      return a.start - b.start;
    });

    if (sortedClips.length > 0) {
      // check the beginning
      if (sortedClips[0].start - minBoundary >= minClipLength) {
        validSlots.push({
          start: 0,
          end: sortedClips[0].start - minBoundary
        });
      }
      // check the gap behind each clips
      sortedClips.forEach((indivisualClip, i) => {
        if (i + 1 < sortedClips.length) {
          if (
            sortedClips[i + 1].start - indivisualClip.end - minBoundary * 2 >=
            minClipLength
          ) {
            validSlots.push({
              start: indivisualClip.end + minBoundary,
              end: sortedClips[i + 1].start - minBoundary
            });
          }
        } else if (
          indivisualClip.end + minBoundary + minClipLength <
          duration
        ) {
          validSlots.push({
            start: indivisualClip.end + minBoundary,
            end: duration
          });
        }
      });
    } else {
      validSlots.push({
        start: 0,
        end: duration
      });
    }
    const fillerDivs = validSlots.map((slot, i) => {
      return (
        <NewClip
          start={slot.start}
          end={slot.end}
          duration={duration}
          position={position}
          cardID={cardID}
          key={i}
        />
      );
    });
    return fillerDivs;
  };

  const deleteClip = useVideoStore((state) => state.deleteClip);
  const deleteClipHandler = (clipIndex) => {
    deleteClip(cardID, position, clipIndex);
  };

  return (
    <div
      className={`${styles.Trimmer} ${disabled ? styles.disabled : ''} trimmer`}
    >
      <div className={styles.fillers}>{renderFiller()}</div>
      {clips.map((clip, index) => {
        const startPoint = (clip.start / duration) * 100;
        const barLength = ((clip.end - clip.start) / duration) * 100;
        const startRef = createRef();
        const endRef = createRef();
        return (
          <div
            className={styles.clipBar}
            style={{ left: `${startPoint}%`, width: `${barLength}%` }}
            key={index}
          >
            <div
              className={styles.delete}
              onClick={() => deleteClipHandler(index)}
            >
              {' '}
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div
              className={styles.startHandle}
              onMouseDown={(e) => mouseDownHandler(e, startRef, index)}
              data-handle='start'
              ref={startRef}
            >
              <FontAwesomeIcon icon={faGripLinesVertical} />
            </div>
            <div className={`${styles.activeBar} activeBar`}>
              <div className={`${styles.clipLength} lengthIndicator`}>
                {(clip.end - clip.start).toFixed(2)}s
              </div>
            </div>
            <div
              className={styles.endHandle}
              onMouseDown={(e) => mouseDownHandler(e, endRef, index)}
              data-handle='end'
              ref={endRef}
            >
              <FontAwesomeIcon icon={faGripLinesVertical} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Trimmer;
