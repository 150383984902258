import React from 'react';
import styles from './Modal.module.scss';

import Aux from '../../hoc/Aux/Aux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMinus } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ show, clickHandler, children, closeIcon }) => {
  return (
    <Aux>
      <div
        className={styles.backdrop}
        style={{
          display: show ? 'flex' : 'none'
        }}
        data-testid='backdrop'
      />
      <div
        className={styles.modal}
        style={{
          transform: show
            ? 'translateY(0) scale(1) skew(0deg, 0deg)'
            : 'translateY(100vh) scale(0) skew(15deg, 0deg)',
          opacity: show ? '1' : '0'
        }}
      >
        {children}
        <div className={styles.close} onClick={() => clickHandler(false)}>
          <FontAwesomeIcon icon={closeIcon ? closeIcon : faMinus} />
        </div>
      </div>
    </Aux>
  );
};

export default Modal;
