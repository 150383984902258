import React from 'react';

import styles from './NewClip.module.scss';

import { useVideoStore } from '../../store/videoStore';

const NewClip = ({ start, end, duration, position, cardID }) => {
  const addNewClip = useVideoStore((state) => state.addNewClip);
  const onClickHandler = () => {
    addNewClip(cardID, position, start, end);
  };
  return (
    <div
      className={styles.NewClip}
      data-testid='newClip'
      style={{
        left: ((start / duration) * 100).toFixed(2) + '%',
        width: (((end - start) / duration) * 100).toFixed(2) + '%'
      }}
      onClick={onClickHandler}
    ></div>
  );
};

export default NewClip;
