import React, { useEffect, useRef } from 'react';

import { useVideoStore } from '../../store/videoStore';
import { useEditorViewStore } from '../../store/editorViewStore';

import styles from './VideoPlayer.module.scss';

const VideoPlayer = ({ video, cardID, assetID, clips }) => {
  const videoRef = useRef(null);
  const setVideoDuration = useVideoStore((state) => state.setVideoDuration);
  const addVideoRef = useEditorViewStore((state) => state.addVideoRef);
  const removeVideoRef = useEditorViewStore((state) => state.removeVideoRef);
  const currentCard = useEditorViewStore((state) => state.currentCard);

  useEffect(() => {
    if (videoRef) {
      addVideoRef(cardID, videoRef);
      return () => {
        // removing the ref on unmount
        removeVideoRef(cardID);
      };
    }
    // eslint-disable-next-line
  }, [videoRef]);

  const videoMetadataLoadHandler = (e) => {
    const duration = e.target.duration;
    setVideoDuration(duration, assetID);
    e.target.currentTime = clips.length ? clips[0].start : 0;
  };

  return (
    <div className={styles.videoPlayer} data-testid='videoPlayer'>
      <video
        src={video}
        controls={currentCard.id === cardID ? true : false}
        ref={videoRef}
        muted={true}
        playsInline={true}
        onLoadedMetadata={videoMetadataLoadHandler}
      ></video>
    </div>
  );
};

export default VideoPlayer;
