import React, { createRef, useEffect } from 'react';

import PreviewVideoController from '../PreviewVideoController/PreviewVideoController';
import { useEditorViewStore } from '../../store/editorViewStore';

import styles from './PreviewVideoPlayer.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const PreviewVideoPlayer = ({ segment }) => {
  const previewStatus = useEditorViewStore((state) => state.previewStatus);

  const canvasRef = createRef(null);

  const initCanvas = (video) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const cw = Math.floor(canvas.getBoundingClientRect().width);
    const videoRatio = video.clientHeight / video.clientWidth;
    const ch = Math.floor(cw * videoRatio);
    canvas.width = cw;
    canvas.height = ch;
    draw(video, context, cw, ch);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const draw = (v, c, w, h) => {
    if (v.paused || v.ended) {
      console.info('current video ended');
    } else {
      c.drawImage(v, 0, 0, w, h);
      setTimeout(draw, 20, v, c, w, h);
    }
  };

  const calcMaxWidth = () => {
    const windowHeight = window.innerHeight;
    const columnMaxW = (windowHeight - 47) * 1.78 * 0.95 - 60; // (windowheight - title height)* 16:9 ratio * 95% - paddings
    return columnMaxW;
  };

  useEffect(() => {
    if (
      previewStatus.playingVideoRef &&
      previewStatus.playingVideoRef.current !== null
    ) {
      clearCanvas();
      initCanvas(previewStatus.playingVideoRef);
    }
  });

  return (
    <div
      className={styles.previewVideo}
      style={{ maxWidth: calcMaxWidth() + 'px' }}
      data-testid='previewVideoPlayer'
    >
      <h1>
        <FontAwesomeIcon icon={faEye} />
        Segment Preview - {segment}
      </h1>
      <canvas ref={canvasRef} />
      <div className={styles.previewController}>
        <PreviewVideoController
          playing={previewStatus.playing}
          canvasRef={canvasRef}
        />
      </div>
    </div>
  );
};

export default PreviewVideoPlayer;
