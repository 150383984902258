import { v4 as uuidv4 } from 'uuid';
import VAST_TAG_TEMPLATE from '../assets/templates/vast_tag.xml';

import { VASTClient } from 'vast-client';

// const lodashClonedeep = require('lodash.clonedeep');

const SWITCHEROO_URL =
  process.env.SWITCHEROO_URL || 'https://video.playground.xyz/switcheroo/v2';

const convertUTCDateToLocalDate = (timestamp) => {
  const date = new Date(timestamp);
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return date.toLocaleString();
};

const generateHash = () => uuidv4();
const flatten = (xs) => [].concat.apply([], xs);

/**
 * Convert the normalised redux store representation of a segment into the form that
 * is expected in the PLAYGROUNDXYZ VAST extension. This includes generating a mediaURL
 * using switcheroo.
 */
const genSegment = async (label, segs, assets) => {
  const state = segs.reduce(
    (s, seg) => {
      const asset = assets.find((a) => a.id === seg.assetID);

      // Convert to VAST clips format (eg. [start, end, start, end])
      const clips =
        seg.clips.length > 0
          ? seg.clips
          : [{ start: 0.0, end: asset.duration }];
      const clipsArr = clips.map((c) => [c.start, c.end]);

      // Convert to switcheroo serialised format (eg. "start:end,start:end")
      const clipsStrings = clipsArr.map((c) => c.join(':')).join(',');

      // Get the clip duration to add to the total duration
      const durationDelta = clips.reduce(
        (count, clip) => count + (clip.end - clip.start),
        0
      );

      return {
        clips: s.clips.concat([clipsArr]),
        clipsStrings: s.clipsStrings.concat([clipsStrings]),
        mediaUrls: s.mediaUrls.concat([encodeURIComponent(asset.url)]),
        duration: s.duration + durationDelta
      };
    },
    { clips: [], clipsStrings: [], mediaUrls: [], duration: 0 }
  );

  const mediaParam = state.mediaUrls.join('|');
  const segsParam = state.clipsStrings.join('|');

  // Switcheroo v2 converts the video and returns it's public URL
  const segment = await fetch(
    `${SWITCHEROO_URL}?media=${mediaParam}&segs=${segsParam}`
  )
    .then(async (response) => {
      const json = await response.json();
      const fileURL = json.mediaUrl;
      return {
        label,
        duration: state.duration,
        clips: flatten(state.clips),
        fileURL
      };
    })
    .catch((error) => {
      console.error(error, 'genSegment');
      return { failed: true, label: label };
    });
  return segment;
};

const generateVastTag = async (name, segments, assets, errorHandler) => {
  const adId = uuidv4();
  const creativeId = uuidv4();

  // Construct the switcheroo URL and VAST meta for each segment
  const segmentJson = [
    await genSegment('S', segments.small, assets),
    await genSegment('M', segments.medium, assets),
    await genSegment('L', segments.large, assets),
    await genSegment('A', segments.audible, assets)
  ];

  const failedSegments = segmentJson.filter((segment) => segment.failed);

  if (failedSegments.length === 0) {
    // Take the audible segment as the default media - note the duration
    // is required to be in format HH:mm:ss
    const durationAsDate = new Date(null);
    durationAsDate.setSeconds(segmentJson[3].duration);
    const duration = durationAsDate.toISOString().substr(11, 8);
    const mediaUrl = segmentJson[3].fileURL;
    const templateContent = atob(
      VAST_TAG_TEMPLATE.replace('data:application/xml;base64,', '')
    );
    const vastTag = templateContent
      .replace('{{AD_ID}}', adId)
      .replace('{{AD_NAME}}', name)
      .replace('{{CREATIVE_ID}}', creativeId)
      .replace('{{MEDIA_URL}}', mediaUrl)
      .replace('{{DURATION}}', duration)
      .replace('{{SEGMENT_JSON}}', JSON.stringify(segmentJson));

    return [vastTag, segmentJson];
  } else {
    const failedSegmentsLabels = failedSegments.reduce((acum, seg) => {
      return acum + ' ' + seg.label;
    }, '');
    errorHandler(
      'Failed to generate segment' + failedSegmentsLabels,
      'genSegment'
    );
    return [];
  }
};

const generateStateURL = (data) => {
  const baseURL = window.location.href;
  const base64data = encodeBase64(data);
  const statefulURL = baseURL + '?state=' + base64data;
  return statefulURL;
};

const encodeBase64 = (data) => {
  const base64data = btoa(JSON.stringify(data));
  return base64data;
};

const decodeBase64 = (data) => {
  try {
    const value = decodeHTMLEntities(data);
    const jsonData = JSON.parse(atob(value));
    return jsonData;
  } catch (e) {
    return null;
  }
};

const decodeHTMLEntities = (text) => {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

const getStateByBuildID = async (buildID, errorHandler) => {
  const creativeURL =
    'https://studio.playgroundxyz.com/' +
    buildID +
    '/quiet?pos=middle-ad&size=300x250&dpframe=0';
  const appState = await fetch(creativeURL)
    .then((res) => res.text())
    .then(
      (result) => {
        const stateKeyWord = '/?state&#x3D;';
        const variableStartIndex = result.search(stateKeyWord);
        if (variableStartIndex !== -1) {
          const tempDoc = result.slice(
            variableStartIndex - 2 + stateKeyWord.length // -2 offsets the extra one character removed from slice and also the string.length
          );
          const variableEndIndex = tempDoc.search('&#x27;');
          const state = tempDoc.slice(0, variableEndIndex);
          return state;
        } else {
          errorHandler(
            "Can't find the VAST TAG variable, please trying building a new one from scratch.",
            'getStateByBuildID'
          );
          console.error(
            "Can't find the VAST TAG variable, please trying building a new one from scratch."
          );
        }
      },
      (error) => {
        errorHandler('Cannot fetch the creative', 'getStateByBuildID');
        console.error('Cannot fetch the creative:', error);
      }
    );
  return decodeBase64(appState);
};

const getStateByState = (location) => {
  const searchParams = new URLSearchParams(location.search);
  return decodeBase64(searchParams.get('state'));
};

const getBuildID = (location) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get('id');
};

const VASTTagToJSON = async (tagURL) => {
  let segments = { small: [], medium: [], large: [], audible: [] },
    assets = [];
  const vastClient = new VASTClient();
  let promise = new Promise((resolve, reject) => {
    vastClient
      .get(tagURL)
      .then((res) => {
        res.ads.forEach((ad) => {
          // search for "xyz-segment" extensions
          if (ad.extensions.length) {
            console.info(ad.extensions);
            ad.extensions.forEach((extension) => {
              if (extension.attributes.type === 'xyz-segment') {
                // parse the value into a JSON
                const xyzSegmentData = JSON.parse(extension.value);
                if (xyzSegmentData.length) {
                  console.info(xyzSegmentData);
                  xyzSegmentData.forEach((segmentData) => {
                    // construct assets object
                    const assetID = generateHash();
                    const asset = {
                      id: assetID,
                      url: segmentData.fileURL,
                      duration: segmentData.duration,
                      name: assetID,
                      uploadTimeStamp: Date.now()
                    };
                    assets.push(asset);
                    // construct segments object
                    let size = '';
                    switch (segmentData.label) {
                      case 'S' || 's':
                        size = 'small';
                        break;
                      case 'M' || 'm':
                        size = 'medium';
                        break;

                      case 'L' || 'l':
                        size = 'large';
                        break;

                      case 'A' || 'a':
                        size = 'audible';
                        break;

                      default:
                        break;
                    }
                    if (segmentData.duration > 0) {
                      // Our VAST Tag does weird shit that still populates empty segment with invalid video URL and gives it a duration of 0
                      segments[size].push({
                        assetID: assetID,
                        clips: [{ start: 0, end: segmentData.duration }]
                      });
                    }
                  });
                }
              }
            });
            resolve({ type: 'extension', segments: segments, assets: assets });
          } else if (ad.creatives.length) {
            // fallback to search for creatives in the tag
            ad.creatives.forEach((creative) => {
              creative.mediaFiles.forEach((mediafile) => {
                if (
                  mediafile.fileURL !== undefined &&
                  mediafile.fileURL !== ''
                ) {
                  const asset = {
                    url: mediafile.fileURL,
                    name: mediafile.fileURL
                  };
                  assets.push(asset);
                }
              });
            });
            if (assets.length) {
              resolve({ type: 'creative', assets: assets });
            } else {
              resolve({
                type: 'error',
                msg: 'This VAST Tag does not contain any media files'
              });
            }
          }
        });
      })
      .catch((err) => {
        resolve({ type: 'error', msg: err });
      });
  });
  let result = await promise;
  return result;
};

export {
  convertUTCDateToLocalDate,
  generateHash,
  generateVastTag,
  generateStateURL,
  getStateByBuildID,
  getStateByState,
  getBuildID,
  VASTTagToJSON
};
