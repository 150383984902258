import create from 'zustand';
// import { mountStoreDevtool } from 'simple-zustand-devtools';

// This store holds most of the other internal UI state
// which controls the behavious of the UI

const useAssetUploadStore = create((set, get) => ({
  //   uploadQueue: [
  //     { name: 'test', progress: 0.3, id: 'foieja', task: { state_: 'paused' } , position: 'large}
  //   ],
  uploadQueue: [],
  addUpload: (data) => {
    const queue = get().uploadQueue;
    const queueCopy = queue.slice();
    queueCopy.push(data);
    set({ uploadQueue: queueCopy });
  },
  updateProgress: (progress, id) => {
    const queue = get().uploadQueue;
    const queueCopy = queue.slice();
    const assetIndex = queueCopy.findIndex((asset) => asset.id === id);
    queueCopy[assetIndex].progress = progress;
    set({ uploadQueue: queueCopy });
  },
  updateError: (error, id) => {
    const queue = get().uploadQueue;
    const queueCopy = queue.slice();
    const assetIndex = queueCopy.findIndex((asset) => asset.id === id);
    queueCopy[assetIndex].error = error;
    set({ uploadQueue: queueCopy });
  },
  removeUploadQueue: (id) => {
    const queue = get().uploadQueue;
    const queueCopy = queue.slice();
    const newQueue = queueCopy.filter((asset) => asset.id !== id);
    set({ uploadQueue: newQueue });
    const validQueue = newQueue.filter(
      (asset) => (asset.error === null) | (asset.error === undefined)
    );
    return validQueue.length;
  }
}));

if (process.env.NODE_ENV === 'development') {
  // mountStoreDevtool('useAssetUploadStore', useAssetUploadStore);
}

export { useAssetUploadStore };
