import React, { useRef, useState } from 'react';

import styles from './VideoCard.module.scss';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import Trimmer from '../Trimmer/Trimmer';

import { useVideoStore } from '../../store/videoStore';
import { useEditorViewStore } from '../../store/editorViewStore';

import { convertUTCDateToLocalDate } from '../../core/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExpandAlt,
  faCompressAlt,
  faClock,
  faCopy,
  faTrashAlt,
  faEllipsisH
} from '@fortawesome/free-solid-svg-icons';

const VideoCard = ({
  assetID,
  clips,
  cardID,
  position,
  expand,
  shrink,
  index
}) => {
  const cardRef = useRef(null);
  const trimmerRef = useRef(null);

  const [clipsState, setClipsState] = useState({
    targetRef: null,
    clipIndex: -1
  });
  const previewStatus = useEditorViewStore((state) => state.previewStatus);
  const previewCurrentTime = useEditorViewStore(
    (state) => state.previewCurrentTime
  );
  const updateClip = useVideoStore((state) => state.updateClip);
  const setExpand = useEditorViewStore((state) => state.setExpand);
  const cancelExpand = useEditorViewStore((state) => state.cancelExpand);
  const closePreviewMode = useEditorViewStore(
    (state) => state.closePreviewMode
  );
  const setCurrentCard = useEditorViewStore((state) => state.setCurrentCard);
  const setError = useEditorViewStore((state) => state.setError);
  const assets = useVideoStore((state) => state.assets);
  const segments = useVideoStore((state) => state.segments);
  const nextEmptySegment = useVideoStore((state) => state.nextEmptySegment);

  const videoAsset = assets.find((asset) => {
    return asset.id === assetID;
  });
  const videoDuration = videoAsset.duration;

  const getVideoFileName = () => {
    const url = videoAsset.url;
    const nameStart = url.lastIndexOf('/');
    const fileName = url.slice(nameStart + 1);
    return fileName;
  };

  const videoRefs = [...useEditorViewStore((state) => state.videoRefs)];
  const videoRef = videoRefs.find((ref) => {
    return ref.cardID === cardID;
  });
  const mouseDownHandler = (e, ref, index) => {
    // setClipsState({ targetRef: ref, clipIndex: index });
    if (ref.current !== null) {
      clipsState.targetRef = ref;
      clipsState.clipIndex = index;
      window.addEventListener('mousemove', mouseMoveHandler, false);
      window.addEventListener('mouseup', mouseUpHandler, false);
    } else {
      console.error('mouse down handler: Cannot find bounding box.');
    }
  };

  const mouseMoveHandler = (e) => {
    if (clipsState.targetRef.current !== null) {
      const compensateOffset =
        e.pageX -
        clipsState.targetRef.current.getBoundingClientRect().left -
        clipsState.targetRef.current.clientWidth / 2;
      const trimmerWidth = trimmerRef.current.clientWidth;
      const movementPercentage = (compensateOffset / trimmerWidth) * 100;
      const target = clipsState.targetRef.current.parentElement;
      if (clipsState.targetRef.current.dataset.handle === 'start') {
        const newLeft = parseFloat(target.style.left) + movementPercentage;
        const newWidth = parseFloat(target.style.width) - movementPercentage;
        const overlapping = checkClipOverLapping(newLeft, newWidth);
        if (newLeft >= 0 && newWidth > 0 && !overlapping) {
          target.style.left = newLeft + '%';
          target.style.width = newWidth + '%';
          const tempClipLength =
            ((newWidth / 100) * videoDuration).toFixed(2) + 's';
          const indicator = target
            .querySelector('.activeBar')
            .querySelector('.lengthIndicator');
          indicator.innerHTML = tempClipLength;
          videoRef.ref.current.currentTime = (newLeft / 100) * videoDuration;
        }
      } else {
        const newWidth = parseFloat(target.style.width) + movementPercentage;
        const overlapping = checkClipOverLapping(
          parseFloat(target.style.left),
          newWidth
        );
        if (
          parseFloat(target.style.left) + newWidth <= 100 &&
          newWidth > 0 &&
          !overlapping
        ) {
          target.style.width = newWidth + '%';
          const tempClipLength =
            ((newWidth / 100) * videoDuration).toFixed(2) + 's';
          const indicator = target
            .querySelector('.activeBar')
            .querySelector('.lengthIndicator');
          indicator.innerHTML = tempClipLength;
          videoRef.ref.current.currentTime =
            ((parseFloat(target.style.left) + newWidth) / 100) * videoDuration;
        }
      }
    } else {
      console.error('mouse move handler: Cannot find bounding box.');
    }
  };

  const mouseUpHandler = (e) => {
    if (clipsState.targetRef.current !== null) {
      const targetClipLeft = parseFloat(
        clipsState.targetRef.current.parentElement.style.left
      );
      const targetClipWidth = parseFloat(
        clipsState.targetRef.current.parentElement.style.width
      );
      updateClip(
        cardID,
        clipsState.clipIndex,
        position,
        targetClipLeft,
        targetClipWidth
      );
      setClipsState({ targetRef: null, clipIndex: -1 });
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    } else {
      console.error('mouse up handler: Cannot find bounding box.');
    }
  };

  const checkClipOverLapping = (start, width) => {
    const newClips = clips.slice();
    newClips.splice(clipsState.clipIndex, 1);

    const overlapping = newClips.filter((clip) => {
      const clipStart = (clip.start / videoDuration) * 100;
      const clipEnd = (clip.end / videoDuration) * 100;
      return (
        (start > clipStart && start < clipEnd) ||
        (width + start < clipEnd && width + start > clipStart) ||
        (width + start > clipEnd && start < clipStart)
      );
    });
    return overlapping.length > 0;
  };

  // const calcClipLength = (clips) => {
  //   let length = 0;
  //   clips.map((clip) => {
  //     length = length + clip.end - clip.start;
  //     return length;
  //   });
  //   return length.toFixed(2);
  // };

  const deleteCard = useVideoStore((state) => state.deleteCard);
  const removeVideoRef = useEditorViewStore((state) => state.removeVideoRef);
  const deleteHandler = (e) => {
    e.stopPropagation();
    // check current segment video length
    if (segments[position].length === 1) {
      cancelExpand();
      closePreviewMode();
      setCurrentCard(null, null);
    } else {
      const nextCardID = segments[position][0].id;
      setCurrentCard(nextCardID, position);
      if (expand) {
        setExpand(nextCardID, position);
      }
    }
    deleteCard(cardID, position);
    removeVideoRef(cardID);
  };

  const copyCard = useVideoStore((state) => state.copyCard);
  const copyHandler = (e) => {
    e.stopPropagation();
    const targetPosition = nextEmptySegment();
    if (targetPosition !== null) {
      const newCard = copyCard(cardID, targetPosition, position);
      setCurrentCard(newCard.id, targetPosition);
    } else {
      setError('Segments are full.', 'Video Copy Handler');
    }
  };

  const scrollToRef = (cardRef) => {
    const topDistance = cardRef.current.getBoundingClientRect().top;
    if (topDistance) {
      window.scrollTo({
        top: topDistance,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const expandHandler = () => {
    if (expand) {
      cancelExpand();
    } else {
      setExpand(cardID, position);
      setTimeout(() => {
        scrollToRef(cardRef);
      }, 100);
    }
  };

  const cardOnClickHandler = () => {
    if (shrink && !expand) {
      setExpand(cardID, position);
    }
    setCurrentCard(cardID, position);
  };

  const renderProgressBar = () => {
    if (previewStatus.playing && previewStatus.cardID === cardID) {
      const trimmerWidth = trimmerRef.current.clientWidth;
      const progressDistance =
        (previewCurrentTime.currentTime / previewCurrentTime.length) *
        trimmerWidth;
      return (
        <div
          className={styles.progressBar}
          style={{ transform: 'translateX(' + progressDistance + 'px)' }}
        ></div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={`${styles.VideoCard}  ${
        expand ? styles.expanded : shrink ? styles.shrink : null
      } ${
        previewStatus.playing && previewStatus.playingIndex !== index
          ? styles.notPlaying
          : ''
      }`}
      ref={cardRef}
      style={{
        cursor: 'move'
      }}
      onMouseDown={cardOnClickHandler}
    >
      <div className={styles.title}>
        {videoAsset.name ? videoAsset.name : getVideoFileName()}
      </div>
      <div
        className={`${styles.expand} expand ${expand ? styles.invert : null}`}
        onClick={expandHandler}
      >
        <FontAwesomeIcon icon={expand ? faCompressAlt : faExpandAlt} />
      </div>
      <div className={styles.timestamp}>
        {convertUTCDateToLocalDate(videoAsset.uploadTimeStamp)}
      </div>
      <div className={styles.videoPlayer}>
        <VideoPlayer
          video={videoAsset.url}
          cardID={cardID}
          assetID={assetID}
          position={position}
          clips={clips}
        />
      </div>
      <div className={styles.trimmerWrap} ref={trimmerRef}>
        <Trimmer
          clips={clips}
          mouseDownHandler={mouseDownHandler}
          duration={videoDuration}
          position={position}
          cardID={cardID}
          disabled={
            previewStatus.playing || position === 'audible' ? true : false
          }
        />
        {renderProgressBar()}
      </div>
      <div className={styles.bottomBar}>
        <div className={styles.clipLength}>
          <FontAwesomeIcon icon={faClock} />
          {/* {calcClipLength(clips)}s from this clip */}
        </div>
        <div
          className={`${styles.actions} ${
            previewStatus.playing ? styles.disabled : ''
          }`}
        >
          <div className={`${styles.copy} button`} onClick={copyHandler}>
            <FontAwesomeIcon icon={faCopy} />
          </div>
          <div className={`${styles.delete} button`} onClick={deleteHandler}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </div>
      </div>
      <div className={styles.more}>
        <FontAwesomeIcon icon={faEllipsisH} />
      </div>
    </div>
  );
};

export default VideoCard;
