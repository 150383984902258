import React, { useEffect } from 'react';

import './App.scss';

import NavigationPanel from './controllers/NavigationPanel/NavigationPanel';
import MainPanel from './controllers/MainPanel/MainPanel';

import { getStateByBuildID, getStateByState, getBuildID } from './core/utils';
import { useVideoStore } from './store/videoStore';
import { useEditorViewStore } from './store/editorViewStore';

const App = () => {
  const updateState = useVideoStore((state) => state.updateState);
  const setError = useEditorViewStore((state) => state.setError);

  useEffect(() => {
    const passParamsToState = (params) => {
      if (params !== null) {
        const assets = params.assets;
        const segments = {
          small: params.segments.small ? params.segments.small : [],
          medium: params.segments.medium ? params.segments.medium : [],
          large: params.segments.large ? params.segments.large : [],
          audible: params.segments.audible ? params.segments.audible : []
        };
        updateState(assets, segments);
      }
      window.history.replaceState({}, document.title, '/');
    };
    const checkGlobalState = async () => {
      const params = await getStateByBuildID(buildID, setError);
      passParamsToState(params);
    };
    const buildID = getBuildID(window.location);
    if (buildID) {
      checkGlobalState();
    } else {
      const params = getStateByState(window.location);
      if (params !== null) passParamsToState(params);
    }
  });

  return (
    <div className='App'>
      <NavigationPanel />
      <MainPanel />
    </div>
  );
};

export default App;
