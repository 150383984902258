//add useState for handling the image as a file and then the image as a url from firebase
import React, { useState } from 'react';

import { useVideoStore } from '../../store/videoStore';
import { useEditorViewStore } from '../../store/editorViewStore';
import { useAssetUploadStore } from '../../store/assetUploadStore';

import { generateHash, VASTTagToJSON } from '../../core/utils';

import styles from './AssetUploader.module.scss';

import { storage } from '../../core/firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
  faTimes,
  faPause,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons';

const AssetUploader = ({ origin }) => {
  const addNewAsset = useVideoStore((state) => state.addNewAsset);
  const nextEmptySegment = useVideoStore((state) => state.nextEmptySegment);
  const addCard = useVideoStore((state) => state.addCard);
  const toggleUploader = useEditorViewStore((state) => state.toggleUploader);
  const showUploader = useEditorViewStore((state) => state.showUploader);

  // UI States
  const [dndStatus, setdndStatus] = useState('');

  const uploadQueue = useAssetUploadStore((state) => state.uploadQueue);
  const addUpload = useAssetUploadStore((state) => state.addUpload);
  const updateProgress = useAssetUploadStore((state) => state.updateProgress);
  const updateError = useAssetUploadStore((state) => state.updateError);
  const removeUploadQueue = useAssetUploadStore(
    (state) => state.removeUploadQueue
  );
  const handlevideoAsFile = (files) => {
    let videoFiles = [];
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(files)) {
      // only accpet mp4 for segment uploader
      if (origin !== 'main' && value.type !== 'video/mp4') {
        value.error = `File is not in a valid format, please try uploading an mp4 file.`;
      } else if (
        origin === 'main' &&
        !(value.type === 'text/xml' || value.type === 'video/mp4')
      ) {
        value.error = `File is not in a valid format, please try uploading an mp4 or xml file.`;
      } else if (value.size > 200000000) {
        value.error = `File is too big, please reduce it below 200MB.`;
      }
      videoFiles.push(value);
    }
    handleFireBaseUpload(videoFiles);
  };

  const handleFireBaseUpload = (videoFiles) => {
    videoFiles.forEach((videoFile) => {
      const queueID = generateHash();
      if (videoFile.error) {
        addUpload({
          name: videoFile.name,
          id: queueID,
          progress: 0,
          error: videoFile.error,
          position: origin
        });
      } else {
        const uploadTask = storage
          .ref(
            `/builder-storage/${
              videoFile.type === 'video/mp4' ? 'video-files' : 'uploaded-tags'
            }/${videoFile.name}`
          )
          .put(videoFile);
        addUpload({
          name: videoFile.name,
          id: queueID,
          task: uploadTask,
          progress: 0,
          position: origin
        });
        uploadTask.on(
          'state_changed',
          (snapShot) => {
            const progress = snapShot.bytesTransferred / snapShot.totalBytes;
            updateProgress(progress, queueID);
          },
          (err) => {
            console.error(err);
            if (err.code !== 'storage/canceled') updateError(err, queueID);
          },
          () => {
            storage
              .ref(
                `/builder-storage/${
                  videoFile.type === 'video/mp4'
                    ? 'video-files'
                    : 'uploaded-tags'
                }/`
              )
              .child(videoFile.name)
              .getDownloadURL()
              .then(async (fireBaseUrl) => {
                const targetPosition =
                  origin === 'main' ? nextEmptySegment() : origin;
                if (targetPosition !== null) {
                  if (videoFile.type === 'video/mp4') {
                    const assetID = addNewAsset(fireBaseUrl, videoFile.name);
                    addCard(targetPosition, assetID);
                    const remainingQueueLength = removeUploadQueue(queueID);
                    if (remainingQueueLength <= 0 && showUploader) {
                      toggleUploader(false);
                    }
                  } else {
                    // fetch VAST tag
                    const tagInfo = await VASTTagToJSON(fireBaseUrl, queueID);
                    if (tagInfo.type === 'extension') {
                      for (const [position, segment] of Object.entries(
                        tagInfo.segments
                      )) {
                        if (segment.length) {
                          segment.forEach((card) => {
                            const asset = tagInfo.assets.filter(
                              (asset) => asset.id === card.assetID
                            );
                            const assetID = addNewAsset(
                              asset[0].url,
                              asset[0].name
                            );
                            addCard(position, assetID, card.clips);
                          });
                        }
                      }
                      const remainingQueueLength = removeUploadQueue(queueID);
                      if (remainingQueueLength <= 0 && showUploader) {
                        toggleUploader(false);
                      }
                    } else if (tagInfo.type === 'creative') {
                      tagInfo.assets.forEach((asset) => {
                        const assetID = addNewAsset(asset.url, asset.name);
                        addCard(targetPosition, assetID);
                      });
                      const remainingQueueLength = removeUploadQueue(queueID);
                      if (remainingQueueLength <= 0 && showUploader) {
                        toggleUploader(false);
                      }
                    } else if (tagInfo.type === 'error') {
                      updateError(tagInfo.msg, queueID);
                    } else {
                      updateError(
                        'Something is wrong with the this file.',
                        queueID
                      );
                    }
                  }
                } else {
                  updateError('Segments are full.', queueID);
                }
              });
          }
        );
      }
    });
  };

  const cancelUploadHandler = (queueID, task) => {
    removeUploadQueue(queueID);
    if (task) task.cancel();
  };

  const pauseUploadHandler = (task) => {
    if (task.state_ === 'paused') {
      task.resume();
    } else {
      task.pause();
    }
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setdndStatus('dragover');
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setdndStatus('');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setdndStatus('');
    const files = e.dataTransfer.files;
    handlevideoAsFile(files);
  };

  const handleTraditionalUpload = (e) => {
    const target = e.target;
    if (target.files && target.files.length >= 1) {
      handlevideoAsFile(target.files);
    }
  };

  const renderUploadBar = (queue, i) => {
    return (
      <div className={styles.uploadQueue} key={i}>
        <div className={styles.fileName}>
          {queue.name}
          <span>{parseInt(queue.progress * 100) + '%'}</span>
        </div>
        <div
          className={styles.progressBar}
          style={{ width: queue.progress * 100 + '%' }}
        ></div>
        <div
          className={
            queue.task.state_ === 'paused'
              ? styles.resumeUpload
              : styles.pauseUpload
          }
          onClick={() => pauseUploadHandler(queue.task)}
        >
          <FontAwesomeIcon
            icon={queue.task.state_ === 'paused' ? faRedoAlt : faPause}
          />
        </div>
        <div
          className={styles.cancelUpload}
          onClick={() => cancelUploadHandler(queue.id, queue.task)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    );
  };

  const renderErrorBar = (queue, i) => {
    return (
      <div className={styles.uploadError} key={i}>
        <div className={styles.fileName}>
          {`Error: ${queue.name} - ${queue.error}`}
        </div>
        <div
          className={styles.cancelUpload}
          onClick={() => cancelUploadHandler(queue.id)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.assetUploader} ${
        origin !== 'main' ? styles.segmentUploader : null
      }`}
    >
      <form
        encType='multipart/form-data'
        method='post'
        action=''
        onDragOver={handleDragIn}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragEnd}
        onDragEnd={handleDragEnd}
        onDrop={handleDrop}
        className={dndStatus === 'dragover' ? styles.dragover : ''}
      >
        <div className={styles.uploader}>
          <input
            className={styles.file}
            type='file'
            name='files[]'
            id='file'
            multiple
            onChange={handleTraditionalUpload}
          />
          <label htmlFor='file'>
            <FontAwesomeIcon icon={faUpload} />
            <strong>
              Upload a {origin === 'main' ? 'VAST tag or ' : null}a MP4 file
            </strong>
            <span className={styles.dragndrop}> or drag it here</span>
          </label>
          <button className={styles.button} type='submit'>
            Upload
          </button>
        </div>
        <div className={styles.uploading}>Uploading…</div>
        <div className={styles.success}>Done!</div>
        <div className={styles.error}>
          Error! <span></span>.
        </div>
      </form>
      <div className={styles.assetDrawer}>
        {uploadQueue.map((queue, i) => {
          // TODO: asset previewer
          if (
            !queue.error &&
            (queue.position === origin) | (origin === 'main')
          ) {
            return renderUploadBar(queue, i);
          } else if (queue.error) {
            return renderErrorBar(queue, i);
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default AssetUploader;
