import React from 'react';

import SegmentPanel from '../SegmentPanel/SegmentPanel';

import styles from './EditView.module.scss';

import { useVideoStore } from '../../store/videoStore';
import Previewer from '../../composites/Previewer/Previewer';

const EditView = () => {
  const segmentData = useVideoStore((state) => state.segments);

  const renderSegmentPanels = () => {
    const segments = Object.keys(segmentData);
    const SegmentPanels = segments.map((segment) => {
      return (
        <SegmentPanel
          segmentName={segment}
          cards={segmentData[segment] ? segmentData[segment] : []}
          key={segment}
        />
      );
    });
    return SegmentPanels;
  };
  return (
    <div className={styles.EditView}>
      <div className={styles.SegmentPanels}>
        {renderSegmentPanels()}
        <Previewer />
      </div>
    </div>
  );
};

export default EditView;
