import React, { useEffect } from 'react';
import styles from './SegmentPanelHeader.module.scss';

// import Toggle from '../../components/Toggle/Toggle';
import Button from '../../components/Button/Button';

import { useEditorViewStore } from '../../store/editorViewStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faPlay,
  faExclamationCircle,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';

const SegmentPanelHeader = ({ title, cards, shrink }) => {
  const recommendedLength = {
    small: 3,
    medium: 4,
    large: 6,
    outro: 2
  };
  // const renderToggle = () => {
  //   const toggle = (
  //     <div
  //       className={`${styles.toggle} ${cards.length ? null : styles.tooltip}`}
  //     >
  //       <Toggle status={cards.length ? true : false} />
  //       {cards.length ? null : (
  //         <div className={styles.tooltiptext}>
  //           Add at least one video to this segment to enable it.
  //         </div>
  //       )}
  //     </div>
  //   );
  //   return toggle;
  // };
  const segmentLength = () => {
    return (
      <div
        className={`${styles.segmentLength} ${
          parseFloat(getTotalLength()) > recommendedLength[title] ||
          parseFloat(getTotalLength()) === 0
            ? styles.fail
            : styles.pass
        }`}
      >
        <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>{' '}
        <span>Segment Length:</span>{' '}
        <span className={styles.totalLength}>{getTotalLength()}s</span>
        <div className={styles.recommended}>
          (Optimal: <span>{recommendedLength[title]}s</span>)
        </div>
      </div>
    );
  };

  const getTotalLength = () => {
    const length = cards.reduce((accumulator, video) => {
      const clipsLength = video.clips.reduce((accu, clip) => {
        return accu + (clip.end - clip.start);
      }, 0);
      accumulator = accumulator + clipsLength;
      return accumulator;
    }, 0);
    return length.toFixed(2);
  };
  const previewMode = useEditorViewStore((state) => state.previewMode);
  const previewStatus = useEditorViewStore((state) => state.previewStatus);
  const setPreviewMode = useEditorViewStore((state) => state.setPreviewMode);
  const updatePreviewStatus = useEditorViewStore(
    (state) => state.updatePreviewStatus
  );
  const setPreviewCurrentTime = useEditorViewStore(
    (state) => state.setPreviewCurrentTime
  );
  const cancelExpand = useEditorViewStore((state) => state.cancelExpand);

  const closePreviewMode = useEditorViewStore(
    (state) => state.closePreviewMode
  );

  const videoRefs = [...useEditorViewStore((state) => state.videoRefs)];

  const previewOnClickHandler = () => {
    if (cards.length) {
      if (previewMode.on) {
        closePreviewMode();
        stopVideoSequence();
      } else {
        setPreviewMode(title);
        updatePreviewStatus(true, null, null, null);
        cancelExpand();
      }
    }
  };

  useEffect(() => {
    if (previewMode.segment === title && previewStatus.playing) {
      // start playing sequence
      initiateVideoSequence(0, 200);
    } else if (
      (previewMode.segment === null) |
      (previewMode.segment === title)
    ) {
      // stop current video that's playing and rewind it to the beginning of the first clip
      stopVideoSequence();
    }
    // eslint-disable-next-line
  }, [previewStatus.playing]);

  const initiateVideoSequence = (currenntVideoIndex, delay) => {
    let currentClipIndex = 0;
    let videoRef = videoRefs.find((ref) => {
      return ref.cardID === cards[currenntVideoIndex].id;
    });
    const videoDOM = videoRef.ref.current;
    // get videoref's id
    const cardID = videoRef.cardID;
    // get clip array
    const clips = cards.find((card) => card.id === cardID).clips;
    if (clips.length) {
      const startTime = clips[currentClipIndex].start;
      videoDOM.currentTime = startTime;

      const monitorSequenceProgress = setInterval(() => {
        const videoCurrentTime = videoDOM.currentTime;
        if (videoCurrentTime >= clips[currentClipIndex].end) {
          console.info(
            'video reaches to the end of the current clip, move on the next clip'
          );
          if (clips.length > currentClipIndex + 1) {
            console.info("there's more clip to play");
            currentClipIndex++;
            videoDOM.currentTime = clips[currentClipIndex].start;
          } else if (cards.length > currenntVideoIndex + 1) {
            console.info("but there's more videos to play");
            videoDOM.pause();
            videoDOM.currentTime = startTime;
            clearInterval(monitorSequenceProgress);
            initiateVideoSequence(currenntVideoIndex + 1, 0);
          } else {
            console.info("and there's no more video/clip");
            stopVideoSequence();
            clearInterval(monitorSequenceProgress);
          }
        } else {
          setPreviewCurrentTime(videoCurrentTime, videoDOM.duration);
        }
      }, 100);
      if (delay) {
        setTimeout(() => {
          videoDOM.play();
          updatePreviewStatus(true, videoDOM, monitorSequenceProgress, cardID);
        }, delay);
      } else {
        videoDOM.play();
        updatePreviewStatus(true, videoDOM, monitorSequenceProgress, cardID);
      }
    } else {
      // no clips defined
      if (cards.length > currenntVideoIndex + 1) {
        // switch to the next video
        initiateVideoSequence(currenntVideoIndex + 1, delay ? delay : 0);
      } else {
        stopVideoSequence();
      }
    }
  };

  const stopVideoSequence = () => {
    videoRefs.forEach((videoRef) => {
      const cardID = videoRef.cardID;
      const matchingVideo = cards.find((card) => card.id === cardID);
      if (matchingVideo) {
        videoRef.ref.current.pause();
        videoRef.ref.current.currentTime = matchingVideo.clips.length
          ? matchingVideo.clips[0].start
          : 0;
      }
    });
    const monitorInterval = previewStatus.monitorInterval;
    clearInterval(monitorInterval);
    updatePreviewStatus(false, null, null, null);
  };

  const requiredSegmentWarning = () => {
    return (
      <div className={styles.segmentWarning}>
        <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>{' '}
        <span>This segment requires at least one asset.</span>
      </div>
    );
  };

  return (
    <div className={`${styles.header} ${shrink ? styles.shrink : ''}`}>
      <h1>
        {title}
        {/* {title === 'outro' ? renderToggle() : null} */}
      </h1>
      {cards.length && title !== 'audible' ? (
        segmentLength()
      ) : title === 'audible' && cards.length ? (
        <div className={styles.placeholder}>
          <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon> Audible
          segment uses the full length of the video
        </div>
      ) : (
        requiredSegmentWarning()
      )}
      <div className={styles.previewWrapper}>
        <Button
          style={{ backgroundColor: 'transparent', borderRadius: '10px' }}
          icon={previewMode.segment === title ? faChevronLeft : faPlay}
          status={
            cards.length &&
            cards.reduce((accum, card) => {
              return accum + card.clips.length;
            }, 0) > 0
              ? true
              : false
          }
          tooltip='This segment requires at least one asset.'
          onClickHandler={cards.length ? previewOnClickHandler : null}
        />
      </div>
    </div>
  );
};

export default SegmentPanelHeader;
