import React from 'react';

import EditView from '../EditView/EditView';

import Modal from '../../components/Modal/Modal';
import AssetUploader from '../../composites/AssetUploader/AssetUploader';
import SaveBuild from '../../composites/SaveBuild/SaveBuild';
import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';

import { useEditorViewStore } from '../../store/editorViewStore';

import styles from './MainPanel.module.scss';

const MainPanel = () => {
  const showUploader = useEditorViewStore((state) => state.showUploader);
  const toggleUploader = useEditorViewStore((state) => state.toggleUploader);
  const showSave = useEditorViewStore((state) => state.showSave);
  const toggleSaveWindow = useEditorViewStore(
    (state) => state.toggleSaveWindow
  );

  return (
    <div className={styles.MainPanel} data-testid='mainpanel'>
      <EditView />
      <Modal clickHandler={toggleUploader} show={showUploader}>
        <AssetUploader origin='main' />
      </Modal>
      <Modal clickHandler={toggleSaveWindow} show={showSave}>
        {/* <SaveBuild type='draft' /> */}
        <SaveBuild type='publish' />
      </Modal>
      <ErrorPanel />
    </div>
  );
};
export default MainPanel;
